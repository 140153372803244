import React from 'react'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGithub,
  faLinkedin,
  faAngellist,
} from '@fortawesome/free-brands-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'

import crv from '../images/backers/crv.png'
import amplify from '../images/backers/amplify.png'
import solomon from '../images/backers/solomon.png'
import olivier from '../images/backers/olivier.png'
import tom from '../images/backers/tom.png'
import ben from '../images/backers/ben.png'

import sf from '../images/sf.jpg'
import madrid from '../images/madrid.jpg'

import conference from '../images/benefits/conference.png'
import equity from '../images/benefits/equity.png'
import hackathon from '../images/benefits/hackathon.png'
import healthcare from '../images/benefits/healthcare.png'
import learning from '../images/benefits/learning.png'
import lunch from '../images/benefits/lunch.png'
import retreat from '../images/benefits/retreat.png'
import setup from '../images/benefits/setup.png'

import logodark from '../images/logo_dark.png'

const AboutUs = () => (
  <Layout>
    <Helmet>
      <body className="about-us" />
    </Helmet>
    <SEO
      title="About us"
      meta={[{ property: 'og:site_name', content: 'Undefined Labs' }]}
      keywords={[
        'developer tools',
        'developers',
        'software',
        'jobs',
        'careers',
      ]}
      description="Check out our open roles! We are a team of developer tools enthusiasts who are passionate about building beautifully designed software
        products, inside and out, to empower every developer in the world to do more, with less."
    />

    <section id="intro">
      <p>
        <strong>
          We build tools to solve challenges in software engineering so
          development teams can do more, faster. Working at Undefined Labs means
          being obsessed with the developer experience and having a passion for
          excellence.
        </strong>
      </p>

      <div className="vcs">
        <p>Backed by great VC firms:</p>
        <div className="crv">
          <a
            href="https://www.crv.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={crv} alt="crv" />
            <p>crv</p>
          </a>
        </div>

        <div className="amplify">
          <a
            href="https://amplifypartners.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={amplify} alt="Amplify Partners" />
            <p>Amplify Partners</p>
          </a>
        </div>
      </div>

      <div className="angels">
        <p>And amazing angels and advisors:</p>
        <div>
          <a
            href="https://www.linkedin.com/in/solomonhykes/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={solomon} alt="Solomon Hykes" />
            <p>Solomon Hykes</p>
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/olivierpomel/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={olivier} alt="Olivier Pomel" />
            <p>Olivier Pomel</p>
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/mojombo/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={tom} alt="Tom Preston-Werner" />
            <p>Tom Preston-Werner</p>
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/bensigelman/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={ben} alt="Ben Sigelman" />
            <p>Ben Sigelman</p>
          </a>
        </div>
      </div>
    </section>

    <section id="offices">
      <h2>Undefined Labs Offices</h2>

      <div className="sf">
        <img src={sf} alt="San Francisco office" />
        <strong>San Francisco</strong>
        <p>
          Our California office is located in downtown Burlingame, just 10
          minutes from Caltrain with easy access to San Francisco and the
          greater Bay Area.
        </p>
      </div>

      <div className="madrid">
        <img src={madrid} alt="Madrid office" />
        <strong>Madrid</strong>
        <p>
          Our Madrid, Spain office is centrally located overlooking Plaza de
          Colón, close to public transportation, surrounded by amazing
          restaurants, and a 10-minute walk from the Buen Retiro Park.
        </p>
      </div>
    </section>

    <section id="about">
      <h2>About Us</h2>
      <p>
        We are a team of developer tools enthusiasts who are passionate about
        building beautifully designed software products, inside and out, to
        empower every developer in the world to do more, with less.
      </p>

      <p className="note">
        We&apos;re cultivating an environment that demands a high sense of
        ownership and accountability, removes obstacles to help everyone perform
        at their best, and emphasize a culture of mentorship and continuous
        learning. We take a day one approach when tackling problems and are
        absolutely obsessed with making developers happy.
      </p>

      <p>Join us in making this an unforgettable journey!</p>
    </section>

    <section id="benefits">
      <h2>Benefits</h2>

      <div className="benefits">
        <div>
          <img src={lunch} alt="lunches" />
          <p>Weekly team lunches</p>
        </div>

        <div>
          <img src={retreat} alt="retreats" />
          <p>Monthly get-togethers & bi-annual company retreats</p>
        </div>

        <div>
          <img src={conference} alt="conferences" />
          <p>Yearly tech conference sponsorships</p>
        </div>

        <div>
          <img src={hackathon} alt="hackathons" />
          <p>Quarterly team hackathons (with prizes!)</p>
        </div>

        <div>
          <img src={equity} alt="equity" />
          <p>Equity - own a piece of the company</p>
        </div>

        <div>
          <img src={healthcare} alt="healthcare" />
          <p>Healthcare for you and dependents</p>
        </div>

        <div>
          <img src={setup} alt="setup" />
          <p>Freedom to choose your perfect work setup</p>
        </div>

        <div>
          <img src={learning} alt="learning" />
          <p>Continual learning stipend</p>
        </div>
      </div>
    </section>

    <footer>
      <div className="copyright">
        <img src={logodark} alt="Undefined Labs logo" />
        <p>Copyright © 2019</p>
      </div>

      <div className="social">
        <ul>
          <li>
            <a href="http://github.com/undefinedlabs">
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/undefinedlabs/about/">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </li>
          <li>
            <a href="https://angel.co/undefined-labs-3">
              <FontAwesomeIcon icon={faAngellist} />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </Layout>
)

export default AboutUs
